import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadsApiCommsService {

  private readonly downloadsUrl = environment.backendServer.mainPath + environment.backendServer.downloads;

  constructor(
    private http: HttpClient
  ) { }

  getSchoolMasterList() {
    const url = `${this.downloadsUrl}/school-list`;
    this.http.get(url, {
      responseType: 'blob'
    })
    .subscribe(data => {
      const fileName = `EE-export - School Master List` +
        `- ${moment().format('YYYYMMDD')}`;

      saveAs(data, fileName);
    });
  }

  getProjectList() {
    const url = `${this.downloadsUrl}/project-list`;
    this.http.get(url, {
      responseType: 'blob'
    })
      .subscribe(data => {
        const fileName = `EE-export - School Project List` +
          `- ${moment().format('YYYYMMDD')}`;

        saveAs(data, fileName);
      });
  }

  getSchoolMasterListForDisplay(): Observable<any[]> {
    const url = `${this.downloadsUrl}/school-list`;
    return this.http.get<any[]>(url);
  }
  
}
