import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, of, startWith } from 'rxjs';
import { ApiCommsService } from 'src/app/core/services/api-comms/api-comms.service';

@Component({
  selector: 'ee-admin-school-master-list',
  templateUrl: './admin-school-master-list.component.html',
  styleUrls: ['./admin-school-master-list.component.scss']
})
export class AdminSchoolMasterListComponent implements OnInit {
  filterValueControl = new FormControl();
  columnFilterControl = new FormControl();
  isFilterVisible = false;

  selectedColumn: string = '';
  selectedDisplayedColumns: string[] = [];
  allColumns: any[] = [];
  displayedColumns: string[] = [];
  filterValues: string[] = [];

  filters: Array<{ column: string, displayName: string, value: string }> = [];
  defaultColumns = ['schoolId', 'schoolName'];
  itemsPerPage = 250;
  totalItems = 0;
  currentPage = 0;
  orderBy = 'schoolId';
  sortDir = 'ASC';
  filteredData: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  filteredOptions: Observable<string[]> = of([]);
  filteredColumns: Observable<any[]> = of([]);
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private apiCommsService: ApiCommsService) {}

  ngOnInit(): void {
    this.loadSchoolMasterList();
    this.dataSource.sort = this.matSort;

    this.dataSource.filterPredicate = (data, filterString: string) => {
      const filters = JSON.parse(filterString);
      return filters.every(filter => {
        const dataValue = data[filter.column] ? data[filter.column].toString().toLowerCase() : '';
        const filterValue = filter.value.toString().toLowerCase();
        return dataValue.includes(filterValue);
      });
    };

    // this.filteredColumns = this.columnFilterControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filterColumns(value))
    // );

    this.columnFilterControl.valueChanges.pipe(
      startWith(''),
      map(value => value ? this._filterColumns(value) : this.allColumns)
    ).subscribe(filtered => {
      this.filteredColumns = of(filtered); 
    });
  
    const columnInput = document.querySelector('input[formControlName="columnFilterControl"]');
    if (columnInput) {
      columnInput.addEventListener('focus', () => {
        this.columnFilterControl.setValue(''); 
      });
    }

    this.updateDisplayedColumns();
  }

  private _filterColumns(value: string): any[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.allColumns.filter(column => 
      column.displayName.toLowerCase().includes(filterValue)
    );
  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => {
      return option && typeof option === 'string' && option.toLowerCase().includes(filterValue);
    });
  }

  toggleFilterPanel(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  displayFn(option: string): string {
    if (this.allColumns && this.allColumns.length > 0) {
      const selectedColumn = this.allColumns.find(col => col.key === option);
      return selectedColumn ? selectedColumn.displayName : option;
    }
    return option;
  }

  loadSchoolMasterList(): void {
    const limit = this.itemsPerPage;
    const offset = this.currentPage * this.itemsPerPage;

    const filterColumns: string[] = [];
    const filterValues: string[] = [];

    if (this.selectedColumn && this.filterValueControl.value) {
      filterColumns.push(this.selectedColumn);
      filterValues.push(this.filterValueControl.value);
    }

    this.apiCommsService.getSchoolMasterListForDisplay(limit, offset, filterColumns, filterValues, this.orderBy, this.sortDir)
      .subscribe((response: any) => {
        this.dataSource.data = response.data;
        this.totalItems = response.totalItems;

        if (response.data.length > 0) {
          this.allColumns = Object.keys(response.data[0]).map(key => ({
            key: key,
            displayName: this.formatColumnName(key)
          }));

          this.allColumns.sort((a, b) => a.displayName.localeCompare(b.displayName));

          this.selectedDisplayedColumns = this.allColumns
            .map(col => col.key)
            .filter(colKey => !['schoolId', 'schoolName', 'description', 'lat', 'long', 'foei'].includes(colKey));
        }

        this.updateDisplayedColumns();
      });
  }

  formatColumnName(key: string): string {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  }
  
  updateDisplayedColumns(): void {
    const fixedColumns = ['schoolId', 'schoolName'];

    const remainingColumns = this.selectedDisplayedColumns
      .filter(col => !fixedColumns.includes(col));

    this.displayedColumns = [...fixedColumns, ...remainingColumns];
  }

  isAllSelected(): boolean {
    const selectableColumns = this.allColumns.map(col => col.key).filter(colKey => !this.isFixedColumn(colKey));
    return selectableColumns.length === this.selectedDisplayedColumns.length;
  }
  
  toggleSelectAll(): void {
    const selectableColumns = this.allColumns.map(col => col.key).filter(colKey => !this.isFixedColumn(colKey));

    if (this.isAllSelected()) {
      this.selectedDisplayedColumns = [...this.defaultColumns];
    } else {
      this.selectedDisplayedColumns = [...this.defaultColumns, ...selectableColumns];
    }
  }

  isColumnDisplayed(columnKey: string): boolean {
    return this.selectedDisplayedColumns.includes(columnKey);
  }

  isFixedColumn(columnKey: string): boolean {
    return ['schoolId', 'schoolName'].includes(columnKey);
  }

  addFilter() {
    const selectedValue = this.filterValueControl.value;
    if (selectedValue && this.selectedColumn) {
      const columnDisplayName = this.allColumns.find(col => col.key === this.selectedColumn).displayName;
      const existingFilterIndex = this.filters.findIndex(filter => filter.column === this.selectedColumn);
      
      if (existingFilterIndex >= 0) {
        this.filters[existingFilterIndex].value = selectedValue;
      } else {
        this.filters.push({ column: this.selectedColumn, displayName: columnDisplayName, value: selectedValue });
      }
      this.selectedColumn = '';
      this.filterValueControl.setValue('');

      this.applyFilters();
    }
  }


  applyFilters() {
  const limit = this.itemsPerPage;
  const offset = this.currentPage * this.itemsPerPage;

  if (this.filters.length > 0) {
    const filterColumns = this.filters.map(filter => filter.column);
    const filterValues = this.filters.map(filter => filter.value);

    this.apiCommsService.getSchoolMasterListForDisplay(
      limit, offset, filterColumns, filterValues, this.orderBy, this.sortDir
    ).subscribe((response: any) => {
      this.dataSource.data = response.data;
      this.filteredData = response.data;
      this.totalItems = response.totalItems;
      localStorage.setItem('filteredData', JSON.stringify(this.filteredData));
    });
  } else {
    this.loadSchoolMasterList();
  }
}

removeFilter(index: number) {
    this.filters.splice(index, 1);
    this.applyFilters();
    if (this.filters.length === 0) {
      this.filteredData = [];
      localStorage.removeItem('filteredData');
    }
    this.selectedColumn = '';
    this.filterValueControl.setValue('');
    this.columnFilterControl.setValue('');
    localStorage.removeItem('filteredData');
  }

  clearAllFilters() {
    this.filters = [];
    this.dataSource.filter = '';
    this.selectedColumn = '';
    if (this.filters.length === 0) {
      this.filteredData = [];
      localStorage.removeItem('filteredData');
    }
    this.filterValueControl.setValue('');
    this.columnFilterControl.setValue('');
    localStorage.removeItem('filteredData');
  }

  changePage(event: PageEvent): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadSchoolMasterList();
  }

  updateSort(event: Sort): void {
    this.orderBy = event.active;
    this.sortDir = event.direction;
    this.loadSchoolMasterList();
  }

  onColumnSelected(columnKey: string): void {
    this.selectedColumn = columnKey;
    this.filterValueControl.setValue('');

    const savedFilteredData = localStorage.getItem('filteredData');
    this.filteredData = savedFilteredData ? JSON.parse(savedFilteredData) : [];

    if (this.filteredData && this.filteredData.length > 0) {
      const distinctValues = Array.from(new Set(this.filteredData.map(item => item[columnKey])));
      this.filterValues = distinctValues.filter(value => value !== null && value !== undefined);
  
      this.filteredOptions = this.filterValueControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, this.filterValues))
      );
    } else {
      this.apiCommsService.getDistinctValuesForColumn(columnKey).subscribe((distinctValues: string[]) => {
        //this.filterValues = distinctValues;
        this.filterValues = distinctValues.filter(value => value !== null && value !== undefined);
        this.filteredOptions = this.filterValueControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, this.filterValues))
        );
      });
    }
  }
  
}