<div class="container">
    <div class="main-container" fxLayout="column">
        <div class="heading-container" fxLayout="column">
            <div class="heading" fxLayout="column">
                <div fxLayout="column">
                    <h3>School Master List</h3>
                </div>
            </div>
        </div>

        <div class="filter-panel">
            <mat-card>
              <mat-card-header>
                <mat-card-title class="header-title">Apply Filters</mat-card-title>
              </mat-card-header>
          
              <mat-card-content>
                <div class="filter-fields-inline" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                  
                  <mat-form-field appearance="outline" class="filter-select" fxFlex="25%">
                    <mat-label>Select a Column</mat-label>
                    <input matInput type="text" [formControl]="columnFilterControl" [matAutocomplete]="columnAuto"/>
                    <mat-autocomplete #columnAuto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let column of filteredColumns | async" [value]="column.key" (click)="onColumnSelected(column.key)">
                        {{ column.displayName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline" class="filter-select" fxFlex="25%">
                    <mat-label>Select Item</mat-label>
                    <input matInput type="text" [formControl]="filterValueControl" [matAutocomplete]="auto"/>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let value of filteredOptions | async" [value]="value">
                        {{ value }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline" class="filter-select" fxFlex="30%">
                    <mat-label>Select Columns to Display</mat-label>
                    <mat-select [(ngModel)]="selectedDisplayedColumns" multiple (selectionChange)="updateDisplayedColumns()">
                      <mat-option *ngFor="let column of allColumns" [value]="column.key" [disabled]="isFixedColumn(column.key)">
                        {{ column.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
          
                  <div fxFlex="20%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button mat-raised-button class="key-action clear-filter" (click)="addFilter()" [disabled]="!selectedColumn || !filterValueControl.value">
                      Add Filter
                    </button>
                    <button mat-raised-button class="key-action clear-filter" (click)="clearAllFilters()">
                      Clear Filter(s)
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          

        <div class="applied-filters" *ngIf="filters.length > 0">
            <mat-chip-list>
                <mat-chip *ngFor="let filter of filters; let i = index" (removed)="removeFilter(i)">
                    {{ filter.displayName }}: {{ filter.value }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>

        <div class="table-container">
            <div class="table-scroll-container" [perfectScrollbar]>
                <table class="default" mat-table [dataSource]="dataSource" matSort (matSortChange)="updateSort($event)">

                    <ng-container matColumnDef="schoolId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>School ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.schoolId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="schoolName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>School Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.schoolName }}</td>
                    </ng-container>

                    <ng-container *ngFor="let column of allColumns">
                        <ng-container *ngIf="isColumnDisplayed(column.key)">
                            <ng-container [matColumnDef]="column.key">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.displayName }}</th>
                                <td mat-cell *matCellDef="let element">{{ element[column.key] }}</td>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="[25, 50, 100]" (page)="changePage($event)">
            </mat-paginator>
        </div>
    </div>
</div>
